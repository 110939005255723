<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod'
import { Field, useForm } from 'vee-validate'
import { z } from 'zod'
import { useCustomFetch } from '../auth/useCustomFetch'

const token = useCookie<{ name: string }>('token')

definePageMeta({
  layout: 'empty',
  title: 'Painel de Soluções',
  // colorMode: 'light',
  preview: {
    title: 'Painel de Soluções',
    description: 'Monitore as cobranças de seu plano e utilize nossas soluções internas.',
    // categories: ['layouts', 'authentication'],
    src: 'https://img.freepik.com/free-vector/technology-wire-mesh-network-connection-digital-background_1017-28407.jpg',
    srcDark: 'https://img.freepik.com/free-vector/technology-wire-mesh-network-connection-digital-background_1017-28407.jpg',
    order: 97,
  },
})

const VALIDATION_TEXT = {
  EMAIL_REQUIRED: 'A valid email is required',
  PASSWORD_REQUIRED: 'A password is required',
}

// This is the Zod schema for the form input
// It's used to define the shape that the form data will have
const zodSchema = z.object({
  email: z.string().email(VALIDATION_TEXT.EMAIL_REQUIRED),
  senha: z.string().min(1, VALIDATION_TEXT.PASSWORD_REQUIRED),
  trustDevice: z.boolean(),
})

// Zod has a great infer method that will
// infer the shape of the schema into a TypeScript type
type FormInput = z.infer<typeof zodSchema>

const validationSchema = toTypedSchema(zodSchema)
const initialValues = computed<FormInput>(() => ({
  email: '',
  senha: '',
  trustDevice: false,
}))

const {
  handleSubmit,
  isSubmitting,
  setFieldError
} = useForm({
  validationSchema,
  initialValues,
})

const router = useRouter()
const toaster = useToaster()

const onSubmit = handleSubmit(async (values) => {
  try {
        const {data} = await useCustomFetch<any>('/auth/login', {
          method: 'POST',
          body: JSON.stringify(values),
        })


        if(!data.value){
          toaster.clearAll()
          toaster.show({
            title: 'Error',
            message: `Dados de acesso invalidos! Verifique seu email e senha.`,
            color: 'danger',
            icon: 'ph:user-circle-fill',
            closable: true,
          })
          return
        }

        if(data.value.error == 1){
          toaster.clearAll()
          toaster.show({
            title: `${data.value.status}`,
            message: `Seu acesso está sob investigação! Caso ache um engano entre em contato.`,
            color: 'danger',
            icon: 'ph:user-circle-fill',
            closable: true,
          })
          return
        }

        if(data.value.first){
          router.push(`/first-access?id=${btoa(data.value.id)}&email=${btoa(data.value.email)}&name=${btoa(data.value.name)}&step=3`)
          return
        }
        
      token.value = data.value.access_token

      toaster.clearAll()
      toaster.show({
        title: 'Success',
        message: `Bem vindo ao seu centro de controle!`,
        color: 'success',
        icon: 'ph:user-circle-fill',
        closable: true,
      })

      router.push('/dashboard')
  } catch (error: any) {

console.log(error)
    toaster.clearAll()
    toaster.show({
      title: 'Error',
      message: `Dados de acesso invalidos! Verifique seu email e senha. (ER: 500)`,
      color: 'danger',
      icon: 'ph:user-circle-fill',
      closable: true,
    })
    return
  }
})

onMounted(() => {
  if (token.value) {
    router.push('/dashboard')
  }

  // if query first-access true, show toaster 
  if(router.currentRoute.value.query.firstAccess){
    toaster.clearAll()
    toaster.show({
      title: 'Obrigado por confiar em nós',
      message: `Preencha os campos com seu e-mail e sua senha!`,
      color: 'success',
      icon: 'ph:user-circle-fill',
      closable: true,
    })
  }
})
</script>

<template>
  <ClientOnly>
    <div class="dark:bg-muted-800 flex min-h-screen bg-white">
      <div
        class="bg-muted-100 dark:bg-muted-900 relative hidden w-0 flex-1 items-center justify-center lg:flex lg:w-3/5"
      >
        <div
          class="w-full h-full"
        >
          
          <img
            src="assets/funil/background.png"
            alt=""
            style="width: 100%; height: 100%;"
          />
        </div>
      </div>
      <div
        class="relative flex flex-1 flex-col justify-center px-6 py-12 lg:w-2/5 lg:flex-none"
      >
        <div class="dark:bg-muted-800 relative mx-auto w-full max-w-sm bg-white">
          
          <div class="flex w-full items-center justify-between">
            <BaseThemeToggle />
          </div>
          <div>
            <BaseHeading
              as="h2"
              size="3xl"
              lead="relaxed"
              weight="medium"
              class="mt-6"
            >
              Bem vindo(a)!
            </BaseHeading>
          </div>

          
          <div class="mt-6">
            <div class="mt-5">
              
              <form
                method="POST"
                action=""
                @submit.prevent="onSubmit"
                class="mt-6"
                novalidate
              >
                <div class="space-y-4">
                  <Field
                    v-slot="{ field, errorMessage, handleChange, handleBlur }"
                    name="email"
                  >
                    <BaseInput
                      :model-value="field.value"
                      :error="errorMessage"
                      :disabled="isSubmitting"
                      type="email"
                      label="Email"
                      placeholder="Email"
                      shape="curved"
                      :classes="{
                        input: 'h-12',
                      }"
                      @update:model-value="handleChange"
                      @blur="handleBlur"
                    />
                  </Field>

                  <Field
                    v-slot="{ field, errorMessage, handleChange, handleBlur }"
                    name="senha"
                  >
                    <BaseInput
                      :model-value="field.value"
                      :error="errorMessage"
                      :disabled="isSubmitting"
                      type="password"
                      label="Senha"
                      placeholder="Senha"
                      shape="curved"
                      :classes="{
                        input: 'h-12',
                      }"
                      @update:model-value="handleChange"
                      @blur="handleBlur"
                    />
                  </Field>
                </div>

                
                <div class="mt-6 flex items-center justify-between">
                  <div class="text-xs leading-5">
                    <NuxtLink
                      to="https://wa.me/551132808396"
                      class="text-primary-600 hover:text-primary-500 font-sans font-medium underline-offset-4 transition duration-150 ease-in-out hover:underline"
                    >
                      Esqueci minha senha
                    </NuxtLink>
                  </div>
                  <div class="text-xs leading-5">
                    <NuxtLink
                      to="/register-l"
                      class="text-info-600 hover:text-info-500 font-sans font-medium underline-offset-4 transition duration-150 ease-in-out hover:underline"
                    >
                      Criar minha conta
                    </NuxtLink>
                  </div>
                </div>

                
                <div class="mt-6">
                  <div class="block w-full rounded-md shadow-sm">
                    <BaseButton
                      :disabled="isSubmitting"
                      :loading="isSubmitting"
                      type="submit"
                      color="primary"
                      shape="curved"
                      class="!h-11 w-full"
                    >
                      Entrar
                    </BaseButton>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ClientOnly>
</template>
